"use client";
import React, { useState, useEffect } from "react";
import { useRouter, useSearchParams } from "next/navigation";
import { setCookieToken, setCookieAdministrador } from "@/cookies";

export default function Module() {
  const router = useRouter();
  const searchParams = useSearchParams();

  const [loadingConnection, setLoadingConnection] = useState(false);
  const [messageConnection, setMessageConnection] = useState("");

  const [usuario, setUsuario] = useState("");
  const [messageUsuario, setMessageUsuario] = useState("");

  const currentYear = new Date().getFullYear();

  const [contrasena, setContrasena] = useState("");
  const [messageContrasena, setMessageContrasena] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const changeUsuario = (event) => {
    setMessageConnection("");
    setMessageUsuario("");
    setUsuario(event.target.value);
  };

  const changeContrasena = (event) => {
    setMessageConnection("");
    setMessageContrasena("");
    setContrasena(event.target.value);
  };

  useEffect(() => {
    if (searchParams.has("session")) {
      setMessageConnection("¡Sesión cerrada por inactividad!");
    }
  }, [searchParams]);

  const onSubmit = async () => {
    if (!usuario.trim()) {
      setMessageUsuario("Ingrese el usuario");
      return;
    }
    if (!contrasena.trim()) {
      setMessageContrasena("Ingrese la contraseña");
      return;
    }

    setLoadingConnection(true);
    try {
      const params = new URLSearchParams({ usuario, contrasena });
      const response = await fetch(`${process.env.apiLogin}login`, {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: params,
      });

      if (response.status === 200) {
        const data = await response.json();
        await setCookieToken(data.token);
        await setCookieAdministrador(
          data.nombre,
          data.puestoLaboral,
          data.rol,
          data.proveedorId,
          data.sucursalId,
        );

        const isValidTransportista = (id) =>
          [
            "n0hqPYY7MRZ98UkbAPPAkYfD8s1aIy3DUDiG2mUeMsHVxKPAwoutq6dXYhkO0oVk",
          ].includes(id);
        router.push(isValidTransportista(data.rol) ? "/viajes" : "/inicio");
      } else if (response.status === 204) {
        setMessageConnection("Credenciales incorrectas!");
      } else {
        setMessageConnection("Error 1ex7q4b1, Intentelo nuevamente");
      }
    } catch (error) {
      setMessageConnection("Error 6gb2a8l9, Intentelo nuevamente");
    } finally {
      setLoadingConnection(false);
    }
  };

  const styles = {
    gradientBackground: {
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: "linear-gradient(to bottom right, #ff9900 50%, #f7f7f7 50%)",
    },
    whiteMask: {
      top: 0,
      left: 0,
      right: 0,
      height: "100%",
      clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 100%)",
      backgroundColor: "#fff",
    },
    loginBox: {
      padding: "20px",
      borderRadius: "8px",
      boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
      backgroundColor: "#fff",
      width: "400px",
      textAlign: "center",
      zIndex: 1,
      margin: "auto",
    },
    inputContainer: {
      position: "relative",
      marginBottom: "15px",
      textAlign: "left",
    },
    input: {
      width: "100%",
      padding: "8px 40px 8px 40px",
      borderRadius: "4px",
      border: "1px solid #ddd",
    },
    icon: {
      position: "absolute",
      left: "10px",
      top: "50%",
      transform: "translateY(-50%)",
    },
    eyeIcon: {
      position: "absolute",
      right: "10px",
      top: "50%",
      transform: "translateY(-50%)",
      cursor: "pointer",
    },
    button: {
      padding: "10px 20px",
      backgroundColor: "#111621",
      color: "white",
      borderRadius: "4px",
      cursor: "pointer",
      marginTop: "10px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    errorText: {
      fontSize: "12px",
      color: "red",
      marginTop: "5px",
    },
    logo: {
      maxWidth: "100%",
      marginBottom: "15px",
    },
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "100vh",
    },
    header: {
      width: "100%",
      height: "50px",
      backgroundColor: "#ff9900",
      padding: "10px",
      textAlign: "center",
      position: "fixed",
      top: 0,
      left: 0,
      zIndex: 1000,
    },
    footer: {
      width: "100%",
      backgroundColor: "#f9f9f9",
      padding: "10px",
      textAlign: "center",
      position: "fixed",
      bottom: 0,
      left: 0,
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.header}></div>
      <div style={styles.loginBox}>
        <img src="/logo-admin.png" alt="Logo GREF" style={styles.logo} />

        <div style={styles.inputContainer}>
          <input
            id="usuario"
            style={styles.input}
            value={usuario}
            maxLength={16}
            placeholder="Ingrese el usuario"
            onChange={changeUsuario}
          />
          <i className="fa-solid fa-users" style={styles.icon}></i>
          {messageUsuario && <p style={styles.errorText}>{messageUsuario}</p>}
        </div>

        <div style={styles.inputContainer}>
          <input
            id="contrasena"
            style={styles.input}
            type={showPassword ? "text" : "password"}
            placeholder="Ingrese la contraseña"
            onChange={changeContrasena}
          />
          <i className="fa-solid fa-key" style={styles.icon}></i>
          <i
            className={`fa-solid ${showPassword ? "fa-eye-slash" : "fa-eye"}`}
            style={styles.eyeIcon}
            onClick={() => setShowPassword(!showPassword)}
          ></i>
          {messageContrasena && (
            <p style={styles.errorText}>{messageContrasena}</p>
          )}
        </div>

        {loadingConnection ? (
          <div style={styles.button}>
            <div
              style={{
                width: "24px",
                height: "24px",
                backgroundImage: `url("/loading.gif")`,
                backgroundPosition: "center",
                backgroundSize: "cover",
              }}
            ></div>
          </div>
        ) : (
          <div style={styles.button} onClick={onSubmit}>
            Entrar
          </div>
        )}
        {messageConnection && (
          <p style={styles.errorText}>{messageConnection}</p>
        )}
      </div>
      <div style={styles.footer}>
        <p style={{ margin: 0 }}>
          GREF | Derechos Reservados {currentYear} | Desarrollado por Coati Systems
        </p>
      </div>
    </div>
  );
}
